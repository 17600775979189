<template>
  <v-container :class="'grey ' + isAlternate ? 'lighten-2' : 'lighten-3'">
    <v-row class="mh-main-row">
      <v-col cols="12" class="pt-0 mh-main-col">
        <v-card outlined>
          <v-card-title
            class="text-lg-h4 mh-page-title text-md-h5 justify-space-between pb-2 flex-row"
          >
            Очереди построения
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-text>
            <v-row align="center">
              <v-col>
                <v-text-field
                  v-model="tableSearchReport"
                  label="Поиск"
                  class="mx-4"
                ></v-text-field>
              </v-col>
              <v-col cols="auto">
                <v-btn color="primary" @click="getQueues"
                  >Обновить очереди</v-btn
                >
              </v-col>
            </v-row>

            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel class="green lighten-4 elevation-0">
                <v-expansion-panel-header class="text-h5"
                  >Очередь 1 [от 15 до 62 дней, без доли] ({{
                    lk_fast_queries_62_days_without_share.length
                  }})</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <que-table
                    :reports="lk_fast_queries_62_days_without_share"
                    :loadingReports="loadingReports"
                    no-data-text="Отчеты не найдены"
                    loading-text="Идет загрузка... Пожалуйста, подождите"
                    :tableSearchReport="tableSearchReport"
                    @deleteItem="deleteItem"
                  ></que-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="green lighten-4 elevation-0">
                <v-expansion-panel-header class="text-h5"
                  >Очередь 2 [от 15 до 62 дней, c долей] ({{
                    lk_fast_queries_62_days_with_share.length
                  }})</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <que-table
                    :reports="lk_fast_queries_62_days_with_share"
                    :loadingReports="loadingReports"
                    no-data-text="Отчеты не найдены"
                    loading-text="Идет загрузка... Пожалуйста, подождите"
                    :tableSearchReport="tableSearchReport"
                    @deleteItem="deleteItem"
                  ></que-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="orange lighten-4 elevation-0">
                <v-expansion-panel-header class="text-h5"
                  >Очередь 3 [от 63 дней, без доли] ({{
                    lk_long_queries_gte_62_days_without_share.length
                  }})</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <que-table
                    :reports="lk_long_queries_gte_62_days_without_share"
                    :loadingReports="loadingReports"
                    no-data-text="Отчеты не найдены"
                    loading-text="Идет загрузка... Пожалуйста, подождите"
                    :tableSearchReport="tableSearchReport"
                    @deleteItem="deleteItem"
                  ></que-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel class="red lighten-4 elevation-0">
                <v-expansion-panel-header class="text-h5"
                  >Очередь 4 [от 63 дней, c долей] ({{
                    lk_long_queries_gte_62_days_with_share.length
                  }})</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <que-table
                    :reports="lk_long_queries_gte_62_days_with_share"
                    :loadingReports="loadingReports"
                    no-data-text="Отчеты не найдены"
                    loading-text="Идет загрузка... Пожалуйста, подождите"
                    :tableSearchReport="tableSearchReport"
                    @deleteItem="deleteItem"
                  ></que-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
        <v-dialog v-model="dialogDelete" max-width="530px" persistent>
          <v-card>
            <v-card-title class="text-h5"
              >Вы уверены, что хотите удалить отчет?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Отмена</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Удалить</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { CONSTS } from "../services/constants";
import QueTable from "@/components/QueTable.vue";
export default {
  components: { QueTable },
  name: "Queues",
  data: () => ({
    panel: [0, 1, 2, 3],
    reportsTabIndex: 0,
    tableSearchReport: "",

    reports: [],

    loadingReports: true,

    editedIndex: -1,

    dialogDelete: false,
  }),
  methods: {
    closeDelete() {
      this.dialogDelete = false;
    },

    deleteItem(item) {
      this.editedIndex = this.reports.indexOf(item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await axios.post(`/delete/` + this.reports[this.editedIndex].uuid);
      this.reports.splice(this.editedIndex, 1);
      this.editedIndex = -1;
      this.closeDelete();
    },

    async getQueues() {
      if (this.$store.getters.isAuthenticated && !this.isAlternate) {
        let res = await axios.post(`/queues`);
        if (res.data) {
          this.reports = res.data;
          this.loadingReports = false;
        }
      }
    },
    timeOutQueues() {
      this.getQueues();
      if (this.$store.getters.isAuthenticated) {
        setTimeout(() => {
          this.timeOutQueues();
        }, 5000);
      }
    },
  },
  mounted() {
    if (this.hasPrivQueues) {
      this.reportsTabIndex = 0;
      this.timeOutQueues();
    }
    if (!this.hasPrivQueues) {
      this.$router.push("/");
    }
  },
  watch: {
    curretnTab() {
      this.editedIndex = -1;
    },
  },
  computed: {
    isAlternate() {
      return process.env.VUE_APP_ALTERNATE_UI == "true";
    },
    lk_fast_queries_62_days_with_share() {
      return this.reports.filter(
        (el) => el.queue == "lk_fast_queries_62_days_with_share"
      );
    },
    lk_fast_queries_62_days_without_share() {
      return this.reports.filter(
        (el) => el.queue == "lk_fast_queries_62_days_without_share"
      );
    },
    lk_long_queries_gte_62_days_with_share() {
      return this.reports.filter(
        (el) => el.queue == "lk_long_queries_gte_62_days_with_share"
      );
    },
    lk_long_queries_gte_62_days_without_share() {
      return this.reports.filter(
        (el) => el.queue == "lk_long_queries_gte_62_days_without_share"
      );
    },
    hasPrivQueues() {
      return !!this.$store.getters.StateUser.indicators[
        CONSTS.PRIVS_KEYS.section_queues
      ];
    },
  },
};
</script>
