<template>
  <v-data-table
      :items="reports"
      :headers="tableReportsHeaders"
      :loading="loadingReports"
      :items-per-page="-1"
      :hide-default-footer="true"
      no-data-text="Отчеты не найдены"
      loading-text="Идет загрузка... Пожалуйста, подождите"
      :search="tableSearchReport"
      :item-class="itemRowBackground"

  >
    <template v-slot:item.actions="{ item }">
      <v-icon small @click="deleteItem(item)"> mdi-close </v-icon>
    </template>
    <template v-slot:item.request.tv_name="{ item }">
      <span v-if="item.request.all_channel == 'true'">Все телеканалы</span>
      <span v-else>{{ item.request.tv_name }}</span>
    </template>
    <template v-slot:item.request.startDate="{ item }">
      <span
      >{{ formatDate(item.request.startDate, false) }} -
        {{ formatDate(item.request.endDate, false) }}</span
      >
    </template>
    <template v-slot:item.report="{ item }">
      <span>{{ getReportType(item.report) }}</span>
    </template>
    <template v-slot:item.created_at="{ item }">
      <span>{{ formatDate(item.created_at) }}</span>
    </template>
    <template v-slot:item.user.email="{ item }">
      <span>{{ item.user.name }} ({{ item.user.email }})</span>
    </template>
    <template v-slot:item.waiting="{ item }">
      <span>{{ getMinutesCount(item.created_at) }} мин</span>
    </template>
    <template v-slot:item.name="{ item }">
      <span v-if="item.status === 'FAILED' || item.status === 'STOPPED'">{{
          item.name || item.uuid
        }}</span>
      <router-link v-else :to="getReportUrl(item)">{{
          item.name || item.uuid
        }}</router-link>
    </template>
  </v-data-table>
</template>

<script>
import moment from "moment";

export default {
  name: "QueTable",
  methods: {
    itemRowBackground: function (item) {
      return item.status  == 'PROGRESS' ? "amber lighten-4" : "";
    },
    deleteItem(item) {
      this.$emit('deleteItem', item);
    },
    getReportType(report) {
      let type = "";
      switch (report) {
        case "general":
          type += "Общий";
          break;
        case "tvchannels":
          type += "Показатели телеканалов";
          break;
        case "compare-tv-period":
          type += "Анализ программирования";
          break;
        case "incomes":
          type += "Приток-отток";
          break;
        case "tvshows":
          type += "Показатели телепередач";
          break;
        case "compare-tv":
          type += "Сетки вещания";
          break;
      }
      return type;
    },
    getMinutesCount(created_at) {
      const now = moment(new Date()); //todays date
      const end = moment(created_at); // another date
      const duration = moment.duration(now.diff(end));
      return duration.asMinutes().toFixed(0);
    },
    formatDate(date, withHour = true) {
      if (withHour)
        return moment(date, "YYYY-MM-DD HH:mm:ss").format("DD.MM.YYYY HH:mm");
      else return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
    },
    getReportUrl(item) {
      let url = "/";
      switch (item.report) {
        case "tvchannels":
          url += "tv/";
          break;
        case "compare-tv-period":
          url += "cast/compare/period/";
          break;
        case "incomes":
          url += "cast/incomes/";
          break;
        case "tvshows":
          url += "cast/tvshows/";
          break;
        case "compare-tv":
          url += "cast/compare/tv/";
          break;
      }
      url += item.uuid;
      return url;
    },
  },
  data() {
    return {
      tableReportsHeaders: [
        {
          text: "Имя",
          value: "name",
          width: 250,
          visible: true,
        },
        {
          text: "Тип",
          value: "report",
          visible: true,
          width: 150,
        },
        {
          text: "Город",
          value: "request.geo_name",
          visible: true,
          width: 180,
        },
        {
          text: "Телеканал",
          value: "request.tv_name",
          visible: true,
        },
        {
          text: "Период",
          value: "request.startDate",
          visible: true,
        },
        {
          text: "Дата создания",
          value: "created_at",
          visible: true,
          width: 150,
        },
        {
          text: "Пользователь",
          value: "user.email",
          visible: true,
          width: 150,
        },

        {
          text: "Уже ждет",
          value: "waiting",
          visible: true,
          width: 150,
        },
        {
          text: "Действия",
          value: "actions",
          visible: true,
          width: 120,
          sortable: false,
        },
      ],
    };
  },
  props: {
    reports: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loadingReports: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    tableSearchReport: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
};
</script>

<style scoped></style>
